.expertise-container {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 20px;
    width: 100%;
    margin: 100px auto;
    max-width: 1200px;
    box-sizing: border-box;
}

.off-css-12 {
    position: absolute;
    top: -150px; 
    left: 2%; 
    color: #f9f9f9;
    font-family: 'Poppins', sans-serif;
    font-size: 64px;
}

.expertise-section {
    display: flex;
    align-items: center; /* Centers content vertically */
    justify-content: space-between;
    gap: 20px;
    flex-direction: row;
    border: 1px solid #f9f9f9;
    background-color: black;
    border-radius: 30px;
    padding: 50px;
    width: 100%;
    /* margin-top: 150px; */
}

.expertise-text-content {
    flex: 1;
    margin-left: 0; 
    display: flex;
    flex-direction: column;
    justify-content: center; 
}

.expertise-section-heading {
    font-size: 64px;
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
    color: #f9f9f9;
    margin-top: -50px;
}

.expertise-lang-91e {
    font-size: 24px;
    margin-bottom: 15px;
    color: #f9f9f9;
    font-family: 'Poppins', sans-serif;
}

.image-rs-10 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Full height to center content */
    padding: 20px;
    box-sizing: border-box;
}

.bulb-image {
    width: 500px;
    max-height: 100%;
    object-fit: contain;
    transform: translateY(0); /* Adjust if needed */
    margin: 0;
}

/* Mobile adjustments for 768px and below */
@media (max-width: 768px) {

    .expertise-lang-91e {
        font-size: 20px;
        margin-bottom: 15px;
        color: #f9f9f9;
        font-family: 'Poppins', sans-serif;
    }
    .expertise-section {
        flex-direction: column; /* Stack vertically on tablets/mobile */
        text-align: center;
        padding: 20px;
        background-color: black;
        border-radius: 30px;
        border: 1px solid #f9f9f9;
    }

    .off-css-12 {
        color: #f9f9f9;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        top: -150px; 
        left: 2%; 
        color: #f9f9f9;
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
    }

    .expertise-section-heading {
        font-size: 38px;
        margin-top: 50px;
    }

    .bulb-image {
        width: 100%; /* Adjust to fit the container */
        max-width: 600px;
    }
}

/* Mobile adjustments for 480px and below */
@media (max-width: 480px) {

    .expertise-lang-91e {
        font-size: 20px;
        margin-bottom: 15px;
        color: #f9f9f9;
        font-family: 'Poppins', sans-serif;
    }
    .expertise-section-heading {
        font-size: 28px;
        margin-top:50px;
    }

    .bulb-image {
        width: 300px;
        max-height: 100%;
        object-fit: contain;
        margin-left: 0;
    }

    .expertise-section {
        padding: 15px;
        border-radius: 30px;
        background-color: black;
        border: 1px solid #f9f9f9;
        /* margin-top: 80px; */
        
    }

    .off-css-12 {
        position: absolute;
        top: -100px; 
        left: 2%; 
        color: #f9f9f9;
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
    }
}
