body {
    background-color: #333;
}

.privacy-page {
    background-color: #333;
    color: #fbf9f9; /* Use white color for text to contrast the background */
    padding: 20px;
}

.privacy-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    align-items: flex-start;
    margin-left: 100px;
    margin-right: 100px;
}

.gloves-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
}

.pat-gloves {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.hist-container {
    /* flex: 2; */
    padding-left: 20px;
    text-align: left;
    padding-right:150px;
    margin-left: 100px;
    margin-right: 300px;
}

.privacy-title {
    color: #f9f9f9;
    font-size: 36px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}

.privacy-body {
    font-size: 18px;
    line-height: 1.6;
    color: #fbf9f9;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .privacy-container {
        flex-direction: column; /* Stack elements vertically */
        align-items: center;
        margin: 0px;
    }

    .gloves-container {
        padding: 0; /* Remove padding for a better fit on mobile */
        margin-bottom: 20px; /* Add spacing between the image and text */
    }

    .hist-container {
        padding: 0 20px; /* Add some padding to the left and right to avoid edges */
        text-align: center; /* Center align text for better readability */
    }

    .privacy-title {
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
    }

    .privacy-body {
        font-size: 16px; /* Slightly reduce font size for readability */
        line-height: 1.4; 
        font-family: 'Poppins', sans-serif;
    }
}

@media (max-width: 480px) {
    .privacy-container {
        flex-direction: column; /* Stack elements vertically */
        align-items: center;
        margin: 0px;
    }

    .gloves-container {
        padding: 0; /* Remove padding for a better fit on mobile */
        margin-bottom: 20px; /* Add spacing between the image and text */
    }

    .hist-container {
        padding: 0 20px; /* Add some padding to the left and right to avoid edges */
        text-align: center; /* Center align text for better readability */
    }

    .privacy-title {
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
    }

    .privacy-body {
        font-size: 16px; /* Slightly reduce font size for readability */
        line-height: 1.4; 
        font-family: 'Poppins', sans-serif;
    }
}

