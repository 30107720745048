/* Navbar container styling */
.navbar {
    position: sticky;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    background-color: #000000;
    padding: 10px 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    align-items: center;
}

/* Logo and company name container */
.logo-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Logo styling */
.logo {
    height: 40px;
    width: 40px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    margin-left: -10px;
}

/* Company name styling */
.company-name {
    font-size: 34px;
    font-style: italic;
    text-decoration: none;
    color: #FFFFFF;
    font-family: "roc-grotesk-wide", sans-serif;
    font-weight: 500;
    font-style: normal;
    padding: 20px;
    position: relative;
    margin-left: -10px;
}
.company-name::after {
    content: "©"; 
    font-size: 12px; 
    position: absolute;
    bottom: 24px; 
    /* right: -15px;  */
    color: #f9f9f9;
}

/* Hamburger icon for mobile */
.hamburger {
    display: none;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
}

/* Right links container */
.right-links {
    display: flex;
    align-items: center;
    gap: 20px;
}

/* Navigation link styling */
.nav-link {
    text-decoration: none;
    color: #FFFFFF;
    font-size: 22px;
}

/* Signup link */
.signup-link {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #f9f9f9;
}

/* Contact link */
.contact-link {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #f9f9f9;
}

.contact-sales {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #f9f9f9;
}

/* Responsive design for mobile */
@media (max-width: 768px) {
    .hamburger {
        display: block;
    }

    .right-links {
        display: none;
        flex-direction: column;
        align-items: center;
        background-color: #000;
        position: absolute;
        top: 60px;
        right: 0;
        width: 100%;
        padding: 20px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

    .right-links.active {
        display: flex;
    }

    .nav-link {
        margin-bottom: 10px;
    }
    .company-name {
        font-size: 24px;
        font-style: italic;
        text-decoration: none;
        color: #f9f9f9;
        font-family: "roc-grotesk-wide", sans-serif;
        font-weight: 500;
        font-style: normal;
    }
    .logo {
        height: 40px;
        width: 40px;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}

@media (max-width: 480px) {
    .company-name {
        font-size: 24px;
        font-style: italic;
        text-decoration: none;
        color: #f9f9f9;
        font-family: "roc-grotesk-wide", sans-serif;
        font-weight: 500;
        font-style: normal;
        margin-left: -20px;
    }
    .logo {
        height: 40px;
        width: 40px;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        margin-left: -15px;
    }
}
