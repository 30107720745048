

/* src/css/Siri.css */

.siri-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0; /* Adjusted for better spacing */
    /* min-height: 100vh;  */
    width: 100%; /* Ensure it takes full width of its parent */
    box-sizing: border-box;
}

.siri-inner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-wrap: wrap;  */
    width: 90%;
}

.call-button {
    padding: 10px;
    border-radius: 12px;
    /* background-color: #6c757d; */
    border: none;
    cursor: pointer;
    transition: transform 0.2s;
    flex: 0 0 auto; /* Prevent stretching */
}

.call-button:hover {
    transform: scale(1.1);
}

.call-button:active {
    transform: scale(0.9);
}

.siri-wave-container {
    width: 100%;
    max-width: 400px; /* Set a maximum width */
    padding: 16px;
    border-radius: 20px;
    overflow: hidden;
    flex: 1 1 300px; /* Allow the wave container to grow and shrink */
    box-sizing: border-box;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .siri-container {
        margin: 15px 0;
    }

    .siri-inner-container {
        flex-direction: row;
        padding-left: 50px;
    }

    .call-button {
        padding: 8px;
        margin-bottom: 10px;
    }

    .siri-wave-container {
        max-width: 300px;
        padding: 12px;
    }
}

@media (max-width: 480px) {
    .siri-container {
        margin: 10px 0;
    }

    .call-button {
        padding: 6px;
        margin-bottom: 8px;
    }

    .siri-wave-container {
        max-width: 250px;
        padding: 8px;
    }
}

