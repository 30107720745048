body {
    background-color: #333;
}

.faq-page {
    background-color: #333;
    color: #fbf9f9; /* Use white color for text to contrast the background */
    padding: 20px;
}

.faq-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    align-items: flex-start;
    margin-right: 100px;
    margin-left: 100px;
}

.gloves-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
}

.faq-title {
    color: #f9f9f9;
    font-size: 64px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    align-self: center;
}

.faq-subtitle {
    color: #f9f9f9;
    font-size: 30px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    align-self: baseline;
}

.faq-body {
    font-size: 18px;
    line-height: 1.6;
    color: #fbf9f9;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .faq-container {
        flex-direction: column; /* Stack elements vertically */
        align-items: center;
        margin: 0px;
    }

    .faq-title {
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        align-self: center;
    }

    .faq-subtitle {
        color: #f9f9f9;
        font-size: 20px;
        margin-bottom: 20px;
        font-family: 'Poppins', sans-serif;
        text-align: left;
        align-self: baseline;
        
    }

    .faq-body {
        font-size: 16px; /* Slightly reduce font size for readability */
        line-height: 1.4; 
        font-family: 'Poppins', sans-serif;
    }
}

@media (max-width: 480px) {
    .faq-container {
        flex-direction: column; 
        /* align-items: center; */
        margin: 0px;
    }

    .faq-title {
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        align-self: center;
    }

    .faq-subtitle {
        color: #f9f9f9;
        font-size: 20px;
        margin-bottom: 20px;
        font-family: 'Poppins', sans-serif;
        text-align: left;
        align-self: baseline;
    }

    .faq-body {
        font-size: 16px; /* Slightly reduce font size for readability */
        line-height: 1.4; 
        font-family: 'Poppins', sans-serif;
        
    }
}

