html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: #333;
}

* {
    box-sizing: border-box;
}
