.interviewsc-container {
    text-align: center;
    padding: 20px;
    /* background-color: #000000; */
    margin: 60px 0;
    color: #fbf9f9;
}

.interviewsc-container > h2 {
    margin-bottom: 20px;
    font-size: 64px;
    font-family: 'Poppins', sans-serif;
    color: #f9f9f9;
}

.product-link {
    color: rgb(111, 111, 230); 
    text-decoration: underline; 
  }
  
  .product-link:hover {
    color: darkblue; 
    text-decoration: none; 
  }

.contact-link {
    color: rgb(111, 111, 230); 
    text-decoration: underline; 
  }
  
  .contact-link:hover {
    color: darkblue; 
    text-decoration: none; 
  }

.interviewsc-grid {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 15px; */
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    justify-items: center; 
    align-items: center; 
}

.interview {
    background-color: black; /* Example background color to contrast with the main background */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    width: 70%;
    margin-bottom: 40px;
}

.interview-gear {
    height: 50px;
    width: 50px;
    margin-bottom: 20px;
}

.interview h3 {
    margin: 10px 0;
    font-size: 24px;
    color: #f9f9f9;
    font-family: 'Poppins', sans-serif;
}

.interview p {
    /* margin: 10px 0; */
    font-size: 18px;
    color: #f9f9f9;
    font-family: 'Poppins', sans-serif;
    margin-left: 40px;
    margin-right: 60px;
}

/* Responsive design for mobile */
@media (max-width: 768px) {

  .interviewsc-container {
    text-align: center;
    padding: 20px;
    /* background-color: #000000; */
    margin: 60px 0;
    color: #fbf9f9;
}

    .product-link {
        color: blue; 
        text-decoration: underline; 
      }
      
      .product-link:hover {
        color: darkblue; 
        text-decoration: none; 
      }
    .contact-link {
        color: blue; 
        text-decoration: underline; 
      }
      
      .contact-link:hover {
        color: darkblue; 
        text-decoration: none; 
      }
    .interviewsc-grid {
        grid-template-columns: 1fr; /* Stack the features in one column */
    }
    .interview {
      cursor: pointer;
      background-color: black;;
    }
}

@media (max-width: 480px) {

  .interviewsc-container {
    text-align: center;
    padding: 20px;
    /* background-color: #000000; */
    margin: 60px 0;
    color: #fbf9f9;
}

  .interview {
    cursor: pointer;
    width: 90%;
    background-color: black;
  }

    .product-link {
        color: rgb(81, 81, 200); 
        text-decoration: underline; 
      }
      
      .product-link:hover {
        color: darkblue; 
        text-decoration: none; 
      }

    .contact-link {
        color: rgb(81, 81, 200); 
        text-decoration: underline; 
      }
      
      .contact-link:hover {
        color: darkblue; 
        text-decoration: none; 
      }
    .interviewsc-container > h2 {
        margin-bottom: 50px;
        font-size: 24px;
        font-family: 'Poppins', sans-serif;
        color: #f9f9f9;
    }
}