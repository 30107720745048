/* css/MainGrid.css */
.ls40-x {
    background-color: blue;
    color: #f9f9f9;
    border-color: blue;
  }
  
  .customButton:hover {
    background-color: darkblue;
    border-color: darkblue;
  }