/* BlogList.css */
.blog-list-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }

  .blog-list-container h1 {
    color: #f9f9f9;
    font-family: 'Poppins', sans-serif;
  }

  .blog-list-container h2 {
    color: #f9f9f9;
    font-family: 'Poppins', sans-serif;
  }

  .blog-list-container p {
    color: #f9f9f9;
    font-family: 'Poppins', sans-serif;
  }
  
  .blog-card-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns for desktop */
    gap: 20px;
  }
  
  .blog-card {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: #fff;
    cursor: pointer;
    transition: transform 0.2s;
    outline: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  
  .blog-card:focus,
  .blog-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    border-color: #aaa;
  }
  
  .blog-card:focus {
    box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4); /* Accessible focus ring */
  }
  
  .blog-card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    display: block; 
  }
  
  .blog-card-content {
    padding: 16px;
    box-sizing: border-box;
  }
  
  .blog-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .blog-info {
    font-size: 1rem;
    color: #f9f9f9;
    margin-bottom: 10px;
  }
  
  .blog-published {
    font-size: 0.9rem;
    color: #f9f9f9;
    margin-bottom: 10px;
  }
  
  /* Prevent body from scrolling when modal is open */
  body.no-scroll {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  /* Modal styles */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the modal is above other content */
    overflow: hidden; /* Prevent modal from causing overflow */
  }
  
  .modal-content {
    background-color: #333;
    padding: 20px;
    border-radius: 8px;
    width: 600px;
    max-width: 90%;
    max-height: 90vh; /* Limit the modal height to 90% of viewport */
    overflow-y: auto; /* Enable vertical scrolling within the modal */
    overflow-x: hidden; /* Prevent horizontal scrolling within the modal */
    text-align: left;
    position: relative;
    box-sizing: border-box; /* Ensure padding is included in width */
    word-break: break-word; /* Prevent long words from causing overflow */
  }

  .modal-blog-content {
    color: #f9f9f9;
    font-family: 'Popins', sans-serif;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    background: none;
    border: none;
    color: #aaa;
    transition: color 0.2s;
  }
  
  .close-button:hover,
  .close-button:focus {
    color: #000;
  }
  
  .modal-hero-image {
    width: 100%;
    height: auto;
    max-width: 100%; /* Prevent images from exceeding modal width */
    display: block; /* Remove any inline spacing */
    margin-bottom: 20px;
  }
  
  .modal-content p {
    overflow-wrap: break-word; /* Standard property */
    word-wrap: break-word; /* Deprecated, but widely supported */
    white-space: normal; /* Ensure text wraps normally */
    margin-bottom: 1em; /* Space between paragraphs */
  }
  
  /* Ensure images do not cause overflow */
  .modal-content img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .blog-card-grid {
      grid-template-columns: 1fr; /* Single column on mobile */
    }
  
    .modal-content {
      width: 100%; /* Full width on mobile */
    }
  }
  