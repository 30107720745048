body {
    background-color: #333;
}

.contact-page {
    background-color: #333;
    color: #fbf9f9;
    padding: 20px;
}

.contact-text {
    color: #fbf9f9;
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-right: 150px;
    padding-left: 50px;
}

iframe {
    border-radius: 8px; /* Add some rounding if needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add a subtle shadow */
    width: 100%; /* Ensure the iframe takes full width on mobile */
    max-width: 100%;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .contact-page {
        padding: 10px; /* Reduce padding to fit smaller screens better */
    }

    .contact-text {
        font-size: 20px; /* Slightly reduce font size for smaller screens */
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }

    iframe {
        width: 100%; /* Ensure it takes the full width of the container */
        height: 400px; /* Adjust height if needed to fit comfortably on smaller screens */
    }
}
.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height to center the spinner vertically */
  }
  
  /* You can customize the spinner if needed */
  .loading-spinner {
    margin: 0 auto;
  }
  
@media (max-width: 400px) {
    .contact-page {
        padding: 10px; /* Reduce padding for extremely small screens */
    }

    .contact-text {
        font-size: 18px; /* Further reduce font size for very small screens */
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }

    iframe {
        width: 100%; /* Make sure iframe fits completely on smaller screens */
        height: 300px; /* Adjust height to make it more compact on very small screens */
    }
}
