/* AdminDashboard.css */

.blog-list-container h1 {
    color: #f9f9f9;
}

.blog-list-container p {
  color: #f9f9f9;
}

.admin-logout-btn {
  background-color: blue;
  color: #f9f9f9;
  padding: 10px;
  margin-bottom: 50px;
  margin-top: 20px;
}

.blog-card-content {
    background-color: rgba(47, 47, 47, 0.8);
}

.delete-button {
    background-color: red;
    color: #f9f9f9;
    padding: 5px;
}

.blog-info {
    color: #f9f9f9;
}

.blog-list-container h2 {
    color: #f9f9f9;
}
.admin-dashboard-container {
    padding: 20px;
    max-width: 700px; /* Reduced from 800px to 700px */
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  /* New Blog Form Styling */
  .new-blog-form {
    background-color: #f5f5f5;
    padding: 25px;
    border-radius: 8px;
    margin-bottom: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box;
  }
  
  .new-blog-form h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Form Group Styling */
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .form-control {
    padding: 10px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s;
    width: 100%; /* Ensure full width */
    box-sizing: border-box; /* Include padding and border in the element's total width */
  }
  
  .form-control:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* Add Blog Button Styling */
  .add-blog-button {
    padding: 12px 20px;
    background-color: #28a745; /* Green color */
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .add-blog-button:hover {
    background-color: #218838;
  }
  
  /* Modal Close Button Styling (if not already in BlogList.css) */
  .modal-content .close-button {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 1.5em;
    color: #aaa;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .modal-content .close-button:hover {
    color: #000;
  }

  /* AdminDashboard.css */

/* ... existing styles ... */

/* Content Input Styling */
.content-input {
    padding: 10px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 100px;
    cursor: pointer;
    transition: border-color 0.3s;
    background-color: #fff;
    display: flex;
    align-items: center;
    overflow: hidden; /* Hide overflow */
    position: relative;
    width: 100%; /* Ensure full width */
    box-sizing: border-box;
  }
  .content-input:hover {
    border-color: #007bff;
  }
  
  .content-preview {
    width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
    max-height: 150px; /* Fixed height */
    overflow-y: auto; /* Enable vertical scrolling */
    padding-right: 10px; /* To prevent content hiding behind scrollbar */
  }
  
  
  .content-preview::-webkit-scrollbar {
    width: 6px;
  }
  
  .content-preview::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 3px;
  }
  
  .content-preview::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 3px;
  }
  
  .content-preview::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  
  .content-placeholder {
    color: #888;
  }
  
  /* Optional: Add a gradient fade at the bottom to indicate more content */
  .content-preview::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
  }

  /* AdminDashboard.css */

/* ... existing styles ... */

/* New Blog Form Styling */
.new-blog-form {
    background-color: #888;
    padding: 25px;
    border-radius: 8px;
    margin-bottom: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Add a fixed width or max-width if necessary */
    width: 70%;
    box-sizing: border-box;
  }

  .quill-editor {
    background-color: #ccc;
  }

  .ql-toolbar {
    background-color: #fff; /* Background color for toolbar */
    border-radius: 8px 8px 0 0; /* Rounded corners at the top */
    border-bottom: 1px solid #ccc; /* Border between toolbar and editor */
  }
  
  
  