body {
    background-color: #333;
}

.case-page {
    background-color: #333;
    color: #fbf9f9; /* Use white color for text to contrast the background */
    padding: 20px;
}

.case-container {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    align-items: flex-start;
    /* margin-left: 100px;
    margin-right: 100px; */
}

.ideas-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px; /* Add some space before the table */
    margin-bottom: 20px; /* Add some space after the table */
  }
  
  .ideas-table td {
    padding: 10px 15px;
    vertical-align: top;
    border: 1px solid #444; /* Add borders for better separation */
  }
  
  .ideas-table .lead-in {
    font-weight: bold;
    color: #f9f9f9; /* Add a distinct color for the lead-in */
    width: 25%; /* Adjust the width of the lead-in column */
  }

.cs-background-img {
 /* border: 1px solid red; */
 
}

.cs-img {
    width: 100%;
   }

   .cs-video-wrapper {
    margin: 20px 0; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.cs-video {
    width: 100%; /* Full width */
    max-width: 800px; /* Limit the video width for larger screens */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Optional: Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
    cursor: pointer;
}

.case-title {
    color: #f9f9f9;
    font-size: 64px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    align-self: center;
}

.case-primary-subtitle {
    color: #f9f9f9;
    font-size: 44px;
    font-family: 'Poppins', sans-serif;
}

.case-subtitle {
    color: #f9f9f9;
    font-size: 28px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}

.case-body {
    font-size: 18px;
    line-height: 1.6;
    color: #fbf9f9;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    
    .case-container {
        flex-direction: column; /* Stack elements vertically */
        align-items: center;
        margin: 0px;
    }
    .cs-video-wrapper {
        margin: 20px 0; 
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .cs-video {
        width: 100%; /* Full width */
        max-width: 800px; /* Limit the video width for larger screens */
        height: auto; /* Maintain aspect ratio */
        border-radius: 10px; /* Optional: Rounded corners */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
    }
    
    .ideas-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px; /* Add some space before the table */
        margin-bottom: 20px; /* Add some space after the table */
      }
      
      .ideas-table td {
        padding: 10px 15px;
        vertical-align: top;
        border: 1px solid #444; /* Add borders for better separation */
      }
      
      .ideas-table .lead-in {
        font-weight: bold;
        color: #f9f9f9; /* Add a distinct color for the lead-in */
        width: 25%; /* Adjust the width of the lead-in column */
      }

    .case-title {
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
    }



    .case-body {
        font-size: 16px;
        line-height: 1.4; 
        font-family: 'Poppins', sans-serif;
    }

    .case-primary-subtitle {
        color: #f9f9f9;
        font-size: 44px;
        font-family: 'Poppins', sans-serif;
    }
    
    .case-subtitle {
        color: #f9f9f9;
        font-size: 28px;
        margin-bottom: 20px;
        font-family: 'Poppins', sans-serif;
    }
}

@media (max-width: 480px) {
    .case-container {
        flex-direction: column; /* Stack elements vertically */
        /* align-items: center; */
        margin: 0px;
    }
    .cs-video-wrapper {
        margin: 20px 0; 
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .cs-video {
        width: 100%; /* Full width */
        max-width: 800px; /* Limit the video width for larger screens */
        height: auto; /* Maintain aspect ratio */
        border-radius: 10px; /* Optional: Rounded corners */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
        cursor: pointer;
    }
    

    .case-title {
        font-size: 38px;
        font-family: 'Poppins', sans-serif;
    }

    .case-body {
        font-size: 16px; /* Slightly reduce font size for readability */
        line-height: 1.4; 
        font-family: 'Poppins', sans-serif;
    }

    .ideas-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px; /* Add some space before the table */
        margin-bottom: 20px; /* Add some space after the table */
      }
      
      .ideas-table td {
        padding: 10px 15px;
        vertical-align: top;
        border: 1px solid #444; /* Add borders for better separation */
      }
      
      .ideas-table .lead-in {
        font-weight: bold;
        color: #f9f9f9; /* Add a distinct color for the lead-in */
        width: 25%; /* Adjust the width of the lead-in column */
      }

      .case-primary-subtitle {
        color: #f9f9f9;
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
    }
    
    .case-subtitle {
        color: #f9f9f9;
        font-size: 22px;
        margin-bottom: 20px;
        font-family: 'Poppins', sans-serif;
        text-align: left;
        align-self: baseline;
    }
}

