/* .content-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 70px;
}

.text-content {
    width: 50%;
}

.text-content p {
    font-size: 22px; 
    color: #fbf9f9; 
    line-height: 1.6;
    text-align: left;
    margin-left: 90px;
}

.audio-content p {
    color: #fbf9f9;
    font-size: 18px;
    margin-right: 50px;
}

.text-content-heading {
    font-size: 64px;
    color: #fbf9f9;
    margin-left: 70px;
    margin-top: 10px;
}

.image-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.image-content p {
    margin-bottom: 10px;
    margin-top: 30px;
    color: #fbf9f9;
}

.image-content audio {
    margin-bottom: 20px;
} */

/* src/css/ContentContainer.css */

.content-container {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    /* justify-content: space-between; */
    padding: 20px;
    max-width: 1200px;
    /* margin: 0 auto; */
    box-sizing: border-box;
    margin-bottom: 50px;
    margin: 100px auto;
}

.text-content {
    /* flex: 1;
    margin-right: 40px; */
    margin-bottom: 20px;
}

.text-content-heading {
    font-size: 64px;
    margin-bottom: 10px;
    color:#fbf9f9;
    text-align: left;
    margin-top: -30px;
    font-family: 'Poppins', sans-serif;
}

.text-content p {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 50px;
    color: #fbf9f9;
    text-align: left;
    padding-right: 10px;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
}

.audio-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 20px;
    border: 1px solid #fbf9f9;
    border-color: #f9f9f9;
    box-shadow: 0 5px 40px 10px #f8f7e633;
    border-radius: 30px;
    margin-top: 50px;
    background-color: black;
}

.aqua-90 .layout_card-small-video {
    max-width: 500px; /* Match img max-width */
    width: 100%; /* Ensure it scales within container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure it fills space nicely */
  }

.audio-content p {
    /* font-size: 20px;
    margin-bottom: 5px;
    font-style: italic;
    color: #fbf9f9;
    text-align: center; */
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
}

.aqua-90 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aqua-90 img {
    max-width: 500px;
}

.demo-sec {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px; 
}

.audio-content p {
    font-size: 20px;
    margin-bottom: 5px;
    /* font-style: italic; */
    color: #fbf9f9;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .text-content p {
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 50px;
        color: #fbf9f9;
        text-align: left;
        padding-right: 10px;
        padding-left: 10px;
        font-family: 'Poppins', sans-serif;
    }
    
    /* .content-container {
        flex-direction: column;
        padding: 15px;
    }

    .text-content {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .audio-content {
        flex-direction: column;
        gap: 15px;
    }
} */
.content-container {
    padding: 15px;
}

.audio-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* border: 1px solid #fbf9f9;
    border-color: #f9f9f9;
    box-shadow: 0 5px 40px 10px #f8f7e633; */
    border-radius: 30px;
    margin-top: 50px;
    background-color: black;
}

.text-content {
    margin-bottom: 20px;
}

.text-content-heading {
    font-size: 38px; /* Reduce font size on smaller screens */
    text-align: left;
    margin-top: -30px;
    font-family: 'Poppins', sans-serif;
}
.aqua-90 img {
    max-width: 300px; /* Reduce max width on smaller screens */
}
.siri-inner-container {
    padding-left: 0px !important;
}
.siri-wave-container {
    width: 100% !important;
    max-width: none !important;
}
}

@media (max-width: 480px) {
    .text-content p {
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 50px;
        color: #fbf9f9;
        text-align: left;
        padding-right: 10px;
        padding-left: 10px;
        font-family: 'Poppins', sans-serif;
    }
    
    /* .content-container {
        padding: 10px;
    }

    .text-content-heading {
        font-size: 64px;
        color: #fbf9f9;
        text-align: center;
    }

    .audio-content p {
        font-size: 18px;
        color: #fbf9f9;
        font-style: italic;
        padding-right: 50px;
    }

    .siri-wave-container {
        width: 100% !important;
        max-width: none !important;
    }
}
 */
 .content-container {
    padding: 10px;
}

.text-content-heading {
    font-size: 38px;
    color: #fbf9f9;
    text-align: left;
    margin-top: -30px;
    font-family: 'Poppins', sans-serif;
}
.audio-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* border: 1px solid #fbf9f9;
    border-color: #f9f9f9;
    box-shadow: 0 5px 40px 10px #f8f7e633; */
    border-radius: 30px;
    margin-top: 50px;
    background-color: black;
}

.audio-content p {
    font-size: 16px;
    color: #fbf9f9;
    /* font-style: italic; */
    font-family: 'Poppins', sans-serif;
    margin-top: 50px;
    margin-left: -10px;
}

.siri-wave-container {
    width: 100% !important;
    max-width: none !important;
}
.siri-inner-container {
    padding-left: 0px !important;
}
.aqua-90 img {
    max-width: 300px; /* Further reduce max width for mobile screens */
}
}