body {
    background-color: #333;
}

.final-notes {
    margin-top: 100px;
}

.product-phone-interact {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 190px;
  
}

.phone-wrapper {
  position: relative;
  z-index: 1;
}

.product-phone {
  width: 70%;
}

.chat-header {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between avatar and name */
  margin-bottom: 5px; /* Add some spacing below the name */
}

.chat-name {
  font-size: 16px;
  font-weight: bold;
  color: #00796b; /* Match the user's color scheme */
  margin-bottom: 5px; /* Add spacing between name and text */
}

.chat-text p {
  margin: 0; /* Reset default margin */
  padding: 0; /* Reset default padding */
  color: #333; /* Default text color */
  background-color: #e0f7fa; /* User bubble background color */
  border-radius: 10px; /* Rounded bubble */
  padding: 8px 12px; /* Add padding inside the bubble */
  line-height: 1.5; /* Line height for better readability */
  text-indent: 0; /* Remove unwanted text indentation */
}


.chat-bubbles {
  position: absolute;
  top: 0;
  left: calc(60% + 20px); /* Position bubbles to the right of the phone */
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 150px;
}

.chat-bubble {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  opacity: 0; /* Initially hidden */
  transform: translateY(50px); /* Slide-up effect */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.chat-bubble.visible {
  opacity: 1; /* Fully visible */
  transform: translateY(0); /* Reset position */
}

.chat-avatar {
  width: 60px;
  height: 40px;
  border-radius: 50%; /* Circular avatar */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00796b; /* Background color for user */
  color: white; /* Text color */
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase; /* Ensure initials are uppercase */
}

.chat-bubble.agent .chat-avatar {
  background-color: #333;
  object-fit: cover;
}

.chat-bubble p {
  /* background-color: #f1f1f1; */
  /* padding: 10px 15px; */
  border-radius: 15px;
  max-width: 70%;
  color: #333;
}

.chat-bubble.user p {
  /* background-color: #e0f7fa;  */
  color: #f9f9f9;
  font-size: 14px;
}

.chat-bubble.agent p {
  /* background-color: #f9f9f9;  */
  color: #f9f9f9;
  font-size: 14px;
}

.product-90-intro {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background-color: #333;
  color: #fbf9f9;
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  /* border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); */
}


.product-90-page {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    background-color: #333;
    color: #fbf9f9;
    font-family: 'Poppins', sans-serif;
    /* border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); */
}

.chat-content {
  display: flex;
  align-items: flex-start; 
  gap: 10px; 
  background-color: #333;
  border: 1px solid #f9f9f9;
  border-radius: 15px;
  padding: 10px;
  background-color: black;
  width: 400px;
}

.product-90-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    align-items: flex-start;
    margin-left: 100px;
    margin-right: 100px;
}



.product-90-title {
    color: #f9f9f9;
    font-size: 36px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}

.product-table {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    background-color: #333;
    color: #fbf9f9;
    font-family: 'Poppins', sans-serif;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  }
  
  /* Table Styling */
  .product-table table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .product-table th,
  .product-table td {
    padding: 15px;
    border-bottom: 1px solid #555;
    text-align: center;
  }
  
  .product-table th {
    font-size: 18px;
    color: #f9f9f9;
    font-weight: 600;
    background-color: #444;
  }
  
  .product-table td {
    font-size: 16px;
  }
  
  /* Checkmark and X Styling */
  .check {
    color: green;
    font-weight: bold;
  }
  
  .x {
    color: red;
    font-weight: bold;
  }
  
  .possible {
    color: orange;
    font-weight: bold;
  }
  
  .ok {
    color: #ffc107; /* Yellow for "Ok" */
    font-weight: bold;
  }
  
  .speed {
    font-style: italic;
    color: #fbf9f9;
  }
  
  /* Optional: Icons for Check and X */
  .check:after {
    content: ' ✓';
  }
  
  .x:after {
    content: ' ✕';
  }

.product-90-body {
    font-size: 18px;
    line-height: 1.6;
    color: #fbf9f9;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}

/* Mobile adjustments */
@media (max-width: 768px) {

  .product-90-intro {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    background-color: #333;
    color: #fbf9f9;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    /* border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); */
  }

    .product-table {
        padding: 15px;
        overflow-x: auto;
      }
      
      .product-table th,
      .product-table td {
        padding: 8px;
      }
      
      .product-table th {
        font-size: 16px;
      }
      
      .product-table td {
        font-size: 14px;
      }

    .product-table table {
        font-size: 14px;
      }
      
      .product-table th,
      .product-table td {
        padding: 10px;
      }
    .product-90-container {
        flex-direction: column; /* Stack elements vertically */
        align-items: center;
        margin-top: 40px;
    }

    .product-90-title {
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
    }

    .product-90-body {
        font-size: 16px; /* Slightly reduce font size for readability */
        line-height: 1.4; 
        font-family: 'Poppins', sans-serif;
    }
}

@media (max-width: 480px) {

  .product-90-intro {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    background-color: #333;
    color: #fbf9f9;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    /* border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); */
  }

  .product-phone-interact {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 100px;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
     margin: 0 auto; /* Center the container itself */
  padding: 0 10px; /* Add padding for some space from edges */
    
  }
  
  .phone-wrapper {
    position: relative;
    width: 100%; /* Ensure it spans the container width */
    display: flex;
    justify-content: center; /* Center the phone horizontally */
  }
  
  .product-phone {
    width: 100%;
    margin-bottom: 30px;
    /* height: 100vh; */
  }
  
  .chat-header {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between avatar and name */
    margin-bottom: 5px; /* Add some spacing below the name */
  }
  
  .chat-name {
    font-size: 16px;
    font-weight: bold;
    color: #00796b; /* Match the user's color scheme */
    margin-bottom: 5px; /* Add spacing between name and text */
  }
  
  .chat-text p {
    margin: 0; /* Reset default margin */
    padding: 0; /* Reset default padding */
    color: #333; /* Default text color */
    background-color: #e0f7fa; /* User bubble background color */
    border-radius: 10px; /* Rounded bubble */
    padding: 8px 12px; /* Add padding inside the bubble */
    line-height: 1.5; /* Line height for better readability */
    text-indent: 0; /* Remove unwanted text indentation */
  }
  
  
  .chat-bubbles {
    position: relative; /* Essential for positioning within its container */
    display: flex;
    flex-direction: column; /* Stack bubbles vertically */
    gap: 15px; /* Add space between bubbles */
    align-items: center; /* Center the bubbles horizontally */
    width: 100%; /* Make sure bubbles container spans full width */
    max-width: 320px; /* Limit bubbles container width */
    margin: 0 auto; /* Center container itself */
    padding: 0 10px; 
    left: 0px;
  }
  
  .chat-bubble {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    opacity: 0; /* Initially hidden */
    transform: translateY(50px); /* Slide-up effect */
    transition: opacity 0.9s ease-in-out, transform 0.9s ease-in-out;
    width: 80%;
  }
  
  .chat-bubble.visible {
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* Reset position */
  }
  
  .chat-avatar {
    width: 60px;
    height: 40px;
    border-radius: 50%; /* Circular avatar */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00796b; /* Background color for user */
    color: white; /* Text color */
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase; 
  }
  
  .chat-bubble.agent .chat-avatar {
    background-color: #333;
    object-fit: cover;
  }
  
  .chat-bubble p {
    border-radius: 15px;
    max-width: 70%;
    color: #333;
  }
  
  .chat-bubble.user p {
    /* background-color: #e0f7fa;  */
    color: #f9f9f9;
    font-size: 14px;
  }
  
  .chat-bubble.agent p {
    /* background-color: #f9f9f9;  */
    color: #f9f9f9;
    font-size: 14px;
  }
  
    .product-90-container {
        flex-direction: column; /* Stack elements vertically */
        align-items: center;
        margin-top: 40px;
    }


    .product-90-title {
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
    }

    .product-90-body {
        font-size: 16px; /* Slightly reduce font size for readability */
        line-height: 1.4; 
        font-family: 'Poppins', sans-serif;
    }
}

