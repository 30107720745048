.features-container {
    text-align: center;
    padding: 20px;
    background-color: #000000;
    margin: 60px 0;
    color: #fbf9f9;
}

.features-container > h2 {
    margin-bottom: 20px;
    font-size: 64px;
    font-family: 'Poppins', sans-serif;
    color: #f9f9f9;
}

.product-link {
    color: rgb(111, 111, 230); 
    text-decoration: underline; 
  }
  
  .product-link:hover {
    color: darkblue; 
    text-decoration: none; 
  }

.contact-link {
    color: rgb(111, 111, 230); 
    text-decoration: underline; 
  }
  
  .contact-link:hover {
    color: darkblue; 
    text-decoration: none; 
  }

.features-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 15px;
}

.feature {
    background-color: #1a1a1a; /* Example background color to contrast with the main background */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.feature-gear {
    height: 50px;
    width: 50px;
    margin-bottom: 20px;
}

.feature h3 {
    margin: 10px 0;
    font-size: 24px;
    color: #f9f9f9;
    font-family: 'Poppins', sans-serif;
}

.feature p {
    /* margin: 10px 0; */
    font-size: 18px;
    color: #f9f9f9;
    font-family: 'Poppins', sans-serif;
    margin-left: 40px;
    margin-right: 60px;
}

/* Responsive design for mobile */
@media (max-width: 768px) {

    .product-link {
        color: blue; 
        text-decoration: underline; 
      }
      
      .product-link:hover {
        color: darkblue; 
        text-decoration: none; 
      }
    .contact-link {
        color: blue; 
        text-decoration: underline; 
      }
      
      .contact-link:hover {
        color: darkblue; 
        text-decoration: none; 
      }
    .features-grid {
        grid-template-columns: 1fr; /* Stack the features in one column */
    }
}

@media (max-width: 480px) {

    .product-link {
        color: rgb(81, 81, 200); 
        text-decoration: underline; 
      }
      
      .product-link:hover {
        color: darkblue; 
        text-decoration: none; 
      }

    .contact-link {
        color: rgb(81, 81, 200); 
        text-decoration: underline; 
      }
      
      .contact-link:hover {
        color: darkblue; 
        text-decoration: none; 
      }
    .features-container > h2 {
        margin-bottom: 20px;
        font-size: 34px;
        font-family: 'Poppins', sans-serif;
        color: #f9f9f9;
    }
}