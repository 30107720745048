.footer_menu-wrapper {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 2rem;
    padding: 20px;
    background-color: #333;
    color: #fff;
}

.footer-company-name {
    font-size: 34px;
    font-style: italic;
    text-decoration: none;
    color: #FFFFFF;
    font-family: "roc-grotesk-wide", sans-serif;
    font-weight: 500;
    font-style: normal;
    /* padding: 20px; */
    position: relative;
}
/* .footer-company-name::after {
    content: "©"; 
    font-size: 12px; 
    position: absolute;
    bottom: 24px; 
    color: #f9f9f9;
} */

.footer-text {
    display: flex;
    flex-direction: column; /* Stack the name and slogan */
}

.footer-branding {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between logo and slogan */
    margin-bottom: 20px; /* Space between branding and columns */
}

.footer-logo {
    width: 40px; 
    height: 40px;
    margin-top: -24px;
}

.footer-slogan {
    font-size: 15px;
    color: #f9f9f9;
    font-family: 'Poppins', sans-serif;
    margin-left: 25px;
}

.footer_column {
    display: flex;
    flex-direction: column;
}

.footer_column h4 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #f9f9f9;
    font-family: 'Poppins', sans-serif;
}

.footer_column ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer_column ul li {
    margin-bottom: 8px;
}

.footer_column ul li a {
    color: #f9f9f9;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s ease;
    font-family: 'Poppins', sans-serif;
}

.footer_column ul li a:hover {
    color: #1e90ff;
}

/* Industries wrapper for two columns */
.industries-wrapper {
    display: flex;
    gap: 20px;
    justify-content: center; /* Center lists horizontally */
}

.industries-header {
    text-align: center;
    margin-bottom: 10px;
}

/* Responsive layout */
@media (max-width: 768px) {
    .footer_menu-wrapper {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 2rem;
    }

    .industries-wrapper {
        flex-direction: column;
    }

    .footer-branding {
        flex-direction: column; /* Stack logo and slogan vertically */
        align-items: center; /* Center items on smaller screens */
    }

    .footer-slogan {
        text-align: center;
        font-family: 'Poppins', sans-serif;
    }
}

@media (max-width: 480px) {
    .footer_menu-wrapper {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .footer-branding {
        flex-direction: column;
        align-items: center;
        gap: 5px; /* Reduce gap for smaller screens */
    }

    .footer-slogan {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
    }

    .footer-logo {
        width: 40px; 
        height: 40px;
        /* margin-bottom: -100px; */
    }

    .footer-company-name {
        font-size: 30px;
        font-style: italic;
        text-decoration: none;
        color: #FFFFFF;
        font-family: "roc-grotesk-wide", sans-serif;
        font-weight: 500;
        font-style: normal;
        /* padding: 20px; */
        position: relative;
    }
}