/* src/css/ErrorPage.css */
.error-page {
    text-align: center;
    padding: 50px;
    background-color: #333;
  }
  
  .error-page h1 {
    font-size: 3rem;
    color: #ff4c4c;
  }
  
  .error-page p {
    font-size: 1.5rem;
    color: #333;
  }
  
  .home-link {
    text-decoration: none;
    color: #007bff;
    font-size: 1.2rem;
    font-weight: bold;
  }
  