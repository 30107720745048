.copyright-container {
    color: #fbf9f9;
    text-align: center;
    padding: 20px;
    font-size: 0.875em; /* Smaller font size for footer text */
    border-top: 1px solid #dee2e6; /* Subtle top border for visual separation */
    background-color: #333; /* Match the color scheme for consistency */
}

.copyright-container p {
    margin: 0; /* Remove default margin to avoid extra space */
}
