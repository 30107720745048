/* .video-container {
    position: relative;
    width: 100%;
    height: 90vh; 
    overflow: hidden;
    margin-bottom: 70px;
}

#trim-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    pointer-events: none; 
}

.overlay-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 2; 
}

.overlay-content h6 {
    font-size: 60px;
    color: #FFFFFF;
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
}

.overlay-content p {
    color: #FFFFFF;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
}

@media (max-width: 768px) {
    .overlay-content h6 {
        font-size: 40px;
        font-family: 'Poppins', sans-serif;
    }

    .overlay-content p {
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
    }
}

@media (max-width: 480px) {
    .overlay-content h6 {
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
    }

    .overlay-content p {
        font-size: 20px;
        font-family: 'Poppins', sans-serif;
    }
} */

/* VideoContainer.css */

/* Global variables from your global.css */
/* VideoContainer.css */

/* Global variables */
/* VideoContainer.css */

/* Global variables */
/* VideoContainer.css */

/* Global variables */
/* VideoContainer.css */

/* Global variables */
/* VideoContainer.css */

/* Global variables */
:root {
    --background: 222.2 84% 4.9%; /* hsl(222.2, 84%, 4.9%) ≈ #0c1321 */
    --foreground: 210 40% 98%; /* hsl(210, 40%, 98%) ≈ #f7fafc */
    --primary: 217.2 91.2% 59.8%; /* hsl(217.2, 91.2%, 59.8%) ≈ #5dade2 */
    --primary-foreground: 222.2 47.4% 11.2%; /* hsl(222.2, 47.4%, 11.2%) ≈ #121b2e */
    --muted-foreground: 215 20.2% 65.1%; /* hsl(215, 20.2%, 65.1%) ≈ #a1a9b8 */
  }
  
  .video-container {
    position: relative;
    width: 100%;
    height: 90vh;
    overflow: hidden;
    margin-bottom: 70px;
    background-color: hsl(var(--background));
  }

  .bouncing-logo {
    position: absolute;
    top: 10%; /* Starting position near top */
    left: 10%; /* Starting position near left */
    z-index: -1; /* Above overlay-content (z-index: 3) */
  }
  
  /* Style the logo image */
  .logo-image {
    width: 100px; /* Adjust size as needed */
    height: auto;
    animation: float 90s infinite ease-in-out; /* Apply the floating animation */
  }
  
  /* Define the floating animation */
  @keyframes float {
    0% {
      transform: translate(0, 0);
    }
    20% {
      transform: translate(60vw, 20vh); /* Move right and down */
    }
    40% {
      transform: translate(40vw, 60vh); /* Shift left and further down */
    }
    60% {
      transform: translate(80vw, 40vh); /* Move far right and up */
    }
    80% {
      transform: translate(20vw, 10vh); /* Back left and up */
    }
    100% {
      transform: translate(0, 0); /* Return to start */
    }
  }
  .background-effects {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .background-effects canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .floating-paper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  
  .floating-item {
    position: absolute;
  }
  
  .floating-card {
    width: 64px; /* 4rem */
    height: 80px; /* 5rem */
    background-color: rgba(255, 255, 255, 0.15); /* Darker: from 0.25 to 0.15 */
    backdrop-filter: blur(2px);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s;
  }
  
  .floating-card:hover {
    transform: scale(1.1);
  }
  
  .floating-card svg {
    width: 32px; /* 2rem */
    height: 32px; /* 2rem */
  }
  
  .icon-purple {
    color: #c084fc; /* Full purple-400 */
  }
  
  .icon-blue {
    color: #60a5fa; /* Full blue-400 */
  }
  
  .icon-green {
    color: #4ade80; /* Full green-400 */
  }
  
  .icon-amber {
    color: #fbbf24; /* Full amber-400 */
  }
  
  /* Overlay content */
  .overlay-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 3;
  }
  
  .overlay-title {
    font-size: 60px;
    color: hsl(var(--foreground));
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
  }
  
  .overlay-text {
    color: hsl(var(--muted-foreground));
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .title-gradient {
    background: linear-gradient(to right, #c084fc, #ec4899);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  
  .overlay-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .btn-primary {
    padding: 12px 24px;
    font-size: 18px;
    background-color: hsl(var(--primary));
    color: hsl(var(--primary-foreground));
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    transition: background-color 0.3s;
  }
  
  .btn-primary:hover {
    background-color: hsl(217.2, 91.2%, 50%);
  }
  
  .btn-outline {
    padding: 12px 24px;
    font-size: 18px;
    background-color: transparent;
    color: hsl(var(--foreground));
    border: 1px solid hsl(var(--primary));
    border-radius: 6px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    transition: background-color 0.3s;
  }
  
  .btn-outline:hover {
    background-color: rgba(93, 173, 226, 0.2);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {

    .logo-image {
      width: 80px;
      animation: float 90s infinite ease-in-out; 
    }

    @keyframes float {
      0% {
        transform: translate(0, 0);
      }
      20% {
        transform: translate(50vw, 15vh);
      }
      40% {
        transform: translate(30vw, 50vh);
      }
      60% {
        transform: translate(70vw, 30vh);
      }
      80% {
        transform: translate(10vw, 10vh);
      }
      100% {
        transform: translate(0, 0);
      }
    }
    .overlay-title {
      font-size: 40px;
    }
  
    .overlay-text {
      font-size: 28px;
    }
  
    .btn-primary, .btn-outline {
      padding: 10px 20px;
      font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .logo-image {
      width: 60px;
      animation: float 90s infinite ease-in-out;
    }

    @keyframes float {
      0% {
        transform: translate(0, 0);
      }
      20% {
        transform: translate(40vw, 10vh);
      }
      40% {
        transform: translate(20vw, 40vh);
      }
      60% {
        transform: translate(60vw, 20vh);
      }
      80% {
        transform: translate(5vw, 5vh);
      }
      100% {
        transform: translate(0, 0);
      }
    }
    .overlay-title {
      font-size: 28px;
    }
  
    .overlay-text {
      font-size: 20px;
    }
  
    .btn-primary, .btn-outline {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
  
  @media (min-width: 640px) {
    .overlay-buttons {
      flex-direction: row;
      gap: 15px;
    }
  }