body {
    background-color: #333;
}

.about-page {
    background-color: #333;
    color: #fbf9f9;
    padding: 20px;
}

.about-img-div {
    display: flex;
    justify-content: center; /* Centers the image horizontally */
    margin: 20px 0; /* Adds space above and below the image */
}

.neon-peeps-img {
    width: 100%; 
    max-width: 800px; 
    height: auto; 
    border-radius: 8px; 
    margin-top: -130px;
    margin-bottom: -130px;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);  */
}

.about-container {
    display: flex;
    flex-direction: column;
    /* margin-top: 80px; */
    align-items: flex-start;
    margin-left: 100px;
    margin-right: 100px;
}

.about-title {
    color: #f9f9f9;
    font-size: 64px;
    margin-bottom: 100px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    align-self: center;
}

.about-subtitle {
    color: #f9f9f9;
    font-size: 26px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}

.about-body {
    font-size: 18px;
    line-height: 1.6;
    color: #fbf9f9;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .about-container {
        flex-direction: column; /* Stack elements vertically */
        align-items: center;
        margin-top: 40px;
    }

    .neon-peeps-img {
        max-width: 100%; /* Ensures the image fits within the container */
    }

    .about-title {
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
    }

    .about-subtitle {
        color: #f9f9f9;
        font-size: 18px;
        margin-bottom: 20px;
        font-family: 'Poppins', sans-serif;
        text-align: left;
        align-self: baseline;
    }

    .about-body {
        font-size: 16px; /* Slightly reduce font size for readability */
        line-height: 1.4; 
        font-family: 'Poppins', sans-serif;
    }
}

@media (max-width: 480px) {
    .about-container {
        display: flex;
        flex-direction: column;
        margin-top: 0px;
        align-items: flex-start;
        margin-left: 0px;
        margin-right: 0px;
    }

    .about-title {
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 40px;
    }

    .neon-peeps-img {
        max-width: 100%;
        margin-top: -100px;
        margin-bottom: -100px;
    }

    .about-body {
        font-size: 16px;
        line-height: 1.4; 
        font-family: 'Poppins', sans-serif;
    }

    .about-subtitle {
        color: #f9f9f9;
        font-size: 18px;
        margin-bottom: 20px;
        font-family: 'Poppins', sans-serif;
        text-align: left;
        align-self: baseline;
    }
}

