.avail-container {
    display: flex;
    padding: 20px;
    width: 100%;
    margin: 100px auto;
    max-width: 1200px;
    box-sizing: border-box; /* Ensure padding doesn't cause overflow */
}

.dutch-container {
    width: 100%;
}

.avail-section {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 20px;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #fbf9f9;
    box-shadow: 0 5px 40px 10px #f8f7e633;
    border-radius: 30px;
    padding: 20px; /* Add padding for spacing */
    box-sizing: border-box;
    width: 100%; 
    background-color: black;
    padding: 70px;
}

.avail-text-content {
    flex: 1;
    width: auto;
    margin: 0; /* Remove excessive margins for mobile */
}

.avail-text-content p {
    margin: 0px; 
}

.avail-section-heading {
    font-size: 64px;
    margin-bottom: 30px;
    font-family: 'Poppins', sans-serif;
    color: #f9f9f9;
    margin-top: -50px;
}

.avail-91e {
    font-size: 20px;
    color: #f9f9f9;
    font-family: 'Poppins', sans-serif;
}

/* Style for the clock image */
.clock-image {
    width: 400px;
    height: auto;
    margin-right: 0; /* Remove margin to fit better in mobile view */
}

/* Mobile adjustments for 768px and below */
@media (max-width: 768px) {
    .avail-section {
        flex-direction: column; /* Stack vertically on tablets/mobile */
        align-items: center;
        text-align: center;
        padding: 15px; /* Adjust padding for smaller screens */
    }

    .avail-section-heading {
        font-size: 36px;
        margin-top: 50px;
    }

    .avail-91e {
        font-size: 20px; /* Reduce font size */
    }

    .clock-image {
        width: 50%; /* Resize image for tablets */
        max-width: 250px;
    }
}

/* Mobile adjustments for 480px and below */
@media (max-width: 480px) {
    .avail-section {
        padding: 10px; 
        background-color: black;
    }

    .avail-section-heading {
        font-size: 28px; /* Further reduce font size */
    }

    .avail-91e {
        font-size: 20px; /* Further reduce font size */
    }

    .clock-image {
        width: 100%;
        max-width: 200px;
        margin-bottom: 100px;
    }
}
