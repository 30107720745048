body {
    background-color: #333;
}

.compliance-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: #333;
    color: #f9f9f9;
    font-family: Arial, sans-serif;
  }
  
  .compliance-table th,
  .compliance-table td {
    border: 1px solid #f9f9f9;
    text-align: left;
    padding: 10px;
  }
  
  .compliance-table th {
    background-color: #333;
    font-weight: bold;
    text-align: center;
    color: #f9f9f9;
  }
  
  .compliance-table tr:nth-child(even) {
    background-color: #333;
  }
  
  .compliance-table tr:hover {
    background-color: #333;
  }
  
  .compliance-table td:first-child {
    font-weight: bold;
  }
  

.compliance-page {
    background-color: #333;
    color: #fbf9f9; /* Use white color for text to contrast the background */
    padding: 20px;
}

.compliance-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    align-items: flex-start;
    margin-right: 100px;
    margin-left: 100px;
}

.compliance-title {
    color: #f9f9f9;
    font-size: 36px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}

.compliance-subtitle {
    color: #f9f9f9;
    font-size: 28px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}

.compliance-body {
    font-size: 18px;
    line-height: 1.6;
    color: #f9f9f9;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    margin-right: 100px;
}

/* Mobile adjustments */
@media (max-width: 768px) {

    .compliance-table {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;
        background-color: #333;
        color: #f9f9f9;
        font-family: Arial, sans-serif;
      }
      
      .compliance-table th,
      .compliance-table td {
        border: 1px solid #f9f9f9;
        text-align: left;
        padding: 10px;
      }
      
      .compliance-table th {
        background-color: #333;
        font-weight: bold;
        text-align: center;
        color: #f9f9f9;
      }
      
      .compliance-table tr:nth-child(even) {
        background-color: #333;
      }
      
      .compliance-table tr:hover {
        background-color: #333;
      }
      
      .compliance-table td:first-child {
        font-weight: bold;
      }
    .compliance-container {
        flex-direction: column; /* Stack elements vertically */
        align-items: center;
        margin: 0px;
    }

    .gloves-container {
        padding: 0; /* Remove padding for a better fit on mobile */
        margin-bottom: 20px; /* Add spacing between the image and text */
    }

    .hist-container {
        padding: 0 20px; /* Add some padding to the left and right to avoid edges */
        text-align: center; /* Center align text for better readability */
    }

    .compliance-title {
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
    }

    .compliance-subtitle {
        color: #f9f9f9;
        font-size: 22px;
        margin-bottom: 20px;
        font-family: 'Poppins', sans-serif;
    }

    .compliance-body {
        font-size: 16px; /* Slightly reduce font size for readability */
        line-height: 1.4; 
        font-family: 'Poppins', sans-serif;
    }
}

@media (max-width: 480px) {

    .table-wrapper {
        overflow-x: auto; /* Enable horizontal scrolling if needed */
        -webkit-overflow-scrolling: touch; 
    }

    .compliance-table {
        width: 100%;
        table-layout: auto;
        border-collapse: collapse;
        /* margin: 20px 0; */
        background-color: #333;
        color: #f9f9f9;
        font-family: Arial, sans-serif;
        overflow-x: auto;
      }
      
      .compliance-table th,
      .compliance-table td {
        border: 1px solid #f9f9f9;
        text-align: left;
        padding: 10px;
      }
      
      .compliance-table th {
        background-color: #333;
        font-weight: bold;
        text-align: center;
        color: #f9f9f9;
      }
      
      .compliance-table tr:nth-child(even) {
        background-color: #333;
      }
      
      .compliance-table tr:hover {
        background-color: #333;
      }
      
      .compliance-table td:first-child {
        font-weight: bold;
      }
    .compliance-container {
        flex-direction: column; /* Stack elements vertically */
        /* align-items: center; */
        margin: 0px;
    }

    .compliance-title {
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
        text-align: left;
        align-self: baseline;
    }

    .compliance-subtitle {
        color: #f9f9f9;
        font-size: 22px;
        margin-bottom: 20px;
        font-family: 'Poppins', sans-serif;
        text-align: left;
        align-self: baseline;
    }

    .compliance-body {
        font-size: 16px;
        line-height: 1.4; 
        font-family: 'Poppins', sans-serif;
        margin: 0px;
    }
}

